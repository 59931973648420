import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import ShareModalStyleWrapper from "./ErrorModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import hoverShapeSmall from "../../../assets/images/icon/hov_shape_s.svg";
import data from "../../../assets/data/socialShare";
const ErrorModal = (props) => {
  const { errorModalHandle } = useModal();
  return (
    <>
      <ShareModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Error!!</h2>
              <button onClick={(e) => errorModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="social_profiles">
                <h3>You have already reserved an NFT for this account. You may only reserve one NFT per account</h3>
                <h3>{props.message}</h3>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </ShareModalStyleWrapper>
    </>
  );
};

export default ErrorModal;
