import React, { useState } from "react";
import { connect } from 'react-redux';
import ImageGridStyle from "./imageGrid.style";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Layout from "../../common/layout";
import Header from "../section/header/v1/Header";
const ImageGrid = (props) => {
  const [active, setActive] = useState({})
  const [selections, setSelections] = useState({nascentCount: 0, genesisCount: 0})
  return (
    <Layout>
       <GlobalStyles />
      <Header/>
      <ImageGridStyle> 
        <h1>Genesis</h1>
        <div className="image-grid">
          {props.genesisCardData.map((card, index) => {
            return (
              <div
                className={`card ${active[card.title] ? 'active' : ''} ${selections[card.title] ? 'selected' : ''}`}
                onMouseDown={() => setActive({ ...active, [card.title]: true })}
                onMouseUp={() => setActive({ ...active, [card.title]: false })}
                onMouseLeave={() => setActive({ ...active, [card.title]: false })}
                onDrag={() => setActive({ ...active, [card.title]: false })}
                onClick={() => {
                  console.log("selections.count:" + selections.count)
                  if(selections[card.title] == true) {
                    selections.genesisCount--
                    setSelections({...selections, [card.title]: false})
                    console.log(selections)

                    //setSelections({...selections, count: selections.count--})

                  } else if(selections.genesisCount < 1) {
                    selections.genesisCount++
                    setSelections({...selections, [card.title]: true})
                    console.log(selections)
                    //setSelections({...selections, count: selections.count++})
                    //console.log(selections)
                  }
                  
                  props.clickCallback(index)
                }
               }
              >
                  <h3>{card.title}</h3>
                  <img src={card.url} width={200} draggable="false"/>
              </div>
            );
          })}
        </div>
      {/* <ImageGridStyle>  */}
        <h1>Nascents</h1>
        <div className="image-grid">
          {props.nascentCardData.map((card, index) => {
            return (
              <div
                className={`card ${active[card.title] ? 'active' : ''} ${selections[card.title] ? 'selected' : ''}`}
                onMouseDown={() => setActive({ ...active, [card.title]: true })}
                onMouseUp={() => setActive({ ...active, [card.title]: false })}
                onMouseLeave={() => setActive({ ...active, [card.title]: false })}
                onDrag={() => setActive({ ...active, [card.title]: false })}
                onClick={() => {
                  console.log("selections.count:" + selections.count)
                  if(selections[card.title] == true) {
                    selections.nascentCount--
                    setSelections({...selections, [card.title]: false})
                    console.log(selections)

                    //setSelections({...selections, count: selections.count--})

                  } else if(selections.nascentCount < 1) {
                    selections.nascentCount++
                    setSelections({...selections, [card.title]: true})
                    console.log(selections)
                    //setSelections({...selections, count: selections.count++})
                    //console.log(selections)
                  }

                  props.clickCallback(index)
                }
               }
              >
                  <h3>{card.title}</h3>
                  <img src={card.url} width={200} draggable="false"/>
              </div>
            );
          })}
        </div>
        </ImageGridStyle>

      {/* </ImageGridStyle> */}
    </Layout>

  );
};

export default ImageGrid;