import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./utils/ContextProvider";
import App from "./app/App";
import { Provider } from "react-redux";
import store from "./utils/reducers";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
  <ContextProvider>
    <BrowserRouter>
    {/* <Provider store={store}> */}
      <App />
      {/* </Provider> */}
    </BrowserRouter>
  </ContextProvider>
);
