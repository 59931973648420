import { useModal } from "../../../../utils/ModalContext";
import { useEffect, useState } from "react";
import DashboardV1Wrapper from "./Dashboard.style.js"
import Layout from "../../../../common/layout";
import GlobalStyles from "../../../../assets/styles/GlobalStyles";
import { TransactionOrderForkEvent } from "@ethersproject/abstract-provider";
import traitOrder from "../../../../traitOrder.json"
const Dashboard = (props) => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [remaining, setRemaining] = useState(0);
  const [reload, setReload] = useState(false)
  const [claimedTraitsFromProp, setClaimedTraits] = useState([])

  //var claimedTraitsFromProp = props.claimedTraits
  useEffect(() => {
    window.scrollTo(0, 0)
    const initialize = async () => {    
        // console.log("initialize() account: " + account.toString())
        // console.log("initialize()")
        var claimedTraits = await props.getTraitsForAccount(account)
        //claimedTraitsFromProp = claimedTraits
        setClaimedTraits(claimedTraits)
    }
    initialize();
    }, []);
    //console.log("Dashboard claimedTraitsFromProp: " + claimedTraitsFromProp.toString())
    // if(claimedTraitsFromProp === undefined) {
    //   console.log("Dashboard !claimedTraitsFromProp: ")

    //   setReload(true)
    //   return
    // }
  return (
    <Layout>
    <GlobalStyles />
    <DashboardV1Wrapper>
    <div className="container">
      <div className="row" >
        <div className="col-lg-6">
          <h1>Congratulations!! You have claimed this NFT. Stay tuned for the redeem date</h1>
          {/* <h3>Follow us on <a href="https://twitter.com/citizen_3025" target="_blank" rel="noreferrer"> Twitter</a></h3> */}
          <div className="image-container" > 
                  <img src="https://nft-images-bucket151605-dev.s3.amazonaws.com/public/images/background/default-background.png" style={{
                    height: '500px',
                    borderRadius: '15px',
                    border: '10px solid white',
                    boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.3)',
                    position: 'absolute',
                    zIndex: 1,}}/>
                  <img src="https://nft-images-bucket151605-dev.s3.amazonaws.com/public/images/character/default-character.png" style={{
                    height: '500px',
                    borderRadius: '2%',
                    position: 'absolute',
                    zIndex: 1,}}/>
                    {
                      claimedTraitsFromProp?.map((trait,index) => {
                        
                        return <img src={"https://nft-images-bucket151605-dev.s3.amazonaws.com/public/" + trait + ""} style={{
                          height: '500px',
                          borderRadius: '2%',
                          position: 'absolute',
                          zIndex: 2 + index,}}/>
                      })
                    }
              </div>
            </div>
            <div className="col-lg-6">
              <div className="metadata">
              <h1>Metadata:</h1>
                {
                  claimedTraitsFromProp?.map((trait, index) => {
                    var traitName = props.getNameOfTraitFromPath(trait)
                    return <div><h2>{Object.keys(traitOrder)[index] + ": " + traitName}</h2><h2>{"Rarity: " + props.mapTraitCnts.get(traitName) + "/3025"}</h2></div>
                  })
                }
              </div>
            </div>
        </div>
      </div>
      </DashboardV1Wrapper>
    </Layout>
  );
};

export default Dashboard;
