import { useModal } from "../../../../utils/ModalContext";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import { useEffect, useState } from "react";
import { totalMintCount } from '../../../../utils/web3mint';
import { mintActive } from "../../../../config";
import Layout from "../../../../common/layout";
import GlobalStyles from "../../../../assets/styles/GlobalStyles";

const TermsAndConditions = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [remaining, setRemaining] = useState(0);
  useEffect(() =>{
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      //console.log(totaltMintedItems);
      setRemaining(totaltMintedItems);
    }

    calculateRemainingItems();
  },[])
  return (
    <Layout>
      <GlobalStyles />
    <BannerV1Wrapper id="">
      <div className="container">
        
        <div className="row">
          <div className="col text-center">
            <h1>Citizen 3025</h1>
            
          </div>
        </div>
        <div className="row">
          {/* <div className="mint_live_circle_sect_frame">
            { mintActive ?
                      <div className="mint_live_circle_sect">
                        <div className="mint_live_circle">
                          <span>
                            <img src={mintLiveDownArrow} alt="" />
                          </span>
                          <span className="mint_live_text rotated-style">
                            <img src={mintLiveText} alt="" />
                          </span>
                        </div>
                      </div>
                      : <div></div>
                    }
            </div> */}
        </div>
      
        <div className="row">
            <div className="col text-center">
              {/* <h1>About</h1> */}
              {/* <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Build your next generation of citizens with Citizen 3025. First-generation NFT holders can build their citizens with airdropped Nascents. Nascents combine with your citizen to produce a next-gen citizen in one of three classes. </h4> */}
              <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Terms & Conditions
Last Updated : December 30, 2022

This website is operated by Wavee, LLC. Throughout the site, the term "Wavee", "we", "us" or "our" as the context may require, refer to Wavee, LLC. By visiting our site and/or purchasing something from us, you engage in our Service and agree to be bound by the following terms and conditions.

We reserve the right to update, change or replace any part of these Terms and Conditions without prior written notice at any time, and it is your responsibility to periodically review these Terms and Conditions to stay informed of updates. Any changes to the Terms and Conditions will be in effect as of the Last Updated date referenced above.

Your continued use of this site after the Last Updated dates will constitute your acceptance and agreement.

By visiting the website of other sites in which you can purchase our products, in particular www.opensea.io, you also engage with and agree to be bound by their terms and conditions.

This website is for informational purposes and serves as a binding contract for purchasers of Wavee NFTs.

Citizen3025 is a generative collection of digital artwork (NFTs) housed and run on the Ethereum Network. Users, Collectors, and Purchasers of these NFTs are solely responsible for the safety and the management of their own private assets, which include, but are not limited to, Ethereum Wallets, validating all transactions and contracts generated by this website prior to and after purchases.

Users certify through purchase that they understand that, as the Citizen3025 smart contract runs on the Ethereum network and is bound by their system and terms, there is no ability to undo, reverse, or restore any transactions.

Any connected services, including this website, are provided “as is” and “as available” without any warranty of any kind. Use of this website constitutes your agreement that you are accepting sole responsibility for any and all transactions involving Wavee digital collectibles.

By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence.

You may not use our products and/or services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</h4>
            </div>
        </div>
        <div className="row">
        {
          mintActive ? 
          <div className="col ">
            <div className="buttons_frame">
            
              <div className="banner_buttons">
                {
                  account ? 
                  <Button lg variant="mint" onClick={() => mintModalHandle()}>
                    {" "}
                    Free Mint now!
                  </Button> :
                  <Button lg variant="mint" onClick={() => connectWalletModalHanlde()}>
                    {" "}
                    Free Mint now!
                  </Button>
                }
              </div>
            </div>
          </div>
          : <div></div>
        }
        </div>
         {/* <div className="row">
          <div className="image_instructions">
          <img
            src={instructionsBackground}
            className="image"
            alt="bithu nft button hover shape"
          />
          </div>
        </div> */}
        <div className="row">
            <div className="col text-center">
            <br></br>
            <br></br>

              {/* <h4>Incubating a Nascent by itself yields <span>a Warrior</span></h4>
              <h4>Incubating a Nascent plus Growth-Genesis will yield <span>a Noble</span></h4>
              <h4>Incubating a Nascent plus a Stem-Genesis will yield <span>Royal</span></h4> */}
              <br></br>
              <br></br>
              <h2>More Coming Soon</h2>
              <br></br>
              <h4>Follow us on <span><a href="https://twitter.com/citizen_3025" target="_blank" rel="noreferrer"> Twitter</a></span> to get the latest up-to-date info for air drops and further developments</h4>
              <br></br>
              <br></br>
              {/* <h1>Free to Own Mint is live!</h1> */}
            </div>
        </div>        
      </div>
    </BannerV1Wrapper>
    </Layout>
  );
};

export default TermsAndConditions;
