import contract from '../contracts/TestContract.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum, maxMintsPerUser } from '../config';



export const mint = async () => {
    console.log("mint()")
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb0419fD582BdD9Cf7F2ac8058d808Bb40c23989B";
        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);
        // let txnHash = await nftContract.mint(ethereum.selectedAddress, mint_amount, {
        //     gasLimit: "285000",
        //     value: ethers.utils.parseEther((0.03 * mint_amount).toString())
        // })
        let txnHash = await nftContract.mintPublic({
                gasLimit: "285000"
                //value: ethers.utils.parseEther((0.03 * mint_amount).toString())
            })
        return txnHash
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb0419fD582BdD9Cf7F2ac8058d808Bb40c23989B";
        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);

        let totalMint = await nftContract.totalSupply();

        return totalMint;
    }
}

export const nftsMintedForAcct = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb0419fD582BdD9Cf7F2ac8058d808Bb40c23989B";
        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);
        let tokens = await nftContract.walletOfOwner(signer.getAddress())
        return tokens.length
    }
}

export const isAddressMinted = async () => {
    var isAddrMinted = false
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb0419fD582BdD9Cf7F2ac8058d808Bb40c23989B";
        const nftContract = new ethers.Contract(contractAddress, contract.abi, signer);
        isAddrMinted = await nftContract.addressMinted(signer.getAddress());
    }
    //console.log("is address minted: " + isAddrMinted)
    return isAddrMinted
}
export const isMintAvailableForUser = async () => {
    // let nftsMinted = await nftsMintedForAcct();
    // return nftsMinted < maxMintsPerUser;
    var isAddrMinted = await isAddressMinted()
    return !isAddrMinted
    //return true;
}

//TODO: figure out if need to handle account switching
export const getCurrentUserAcct = async () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    
    //await provider.send("eth_requestAccounts", [])
    const address = await provider.getSigner().getAddress()
    return address;
}
