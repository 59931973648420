import { connect } from 'react-redux';
import axios from 'axios';
import ImageGrid from './imageGrid'
import { isMetaMaskInstalled, ethereum, maxMintsPerUser, isWalletConnected } from '../../config';
import { useModal } from "../../utils/ModalContext";
import ConnectWallet from '../../common/modal/metamask/ConnectWallet';
import Layout from '../../common/layout';
import GlobalStyles from '../../assets/styles/GlobalStyles';
import Header from '../section/header/v1/Header';
import WalletModal from '../../common/modal/walletModal/WalletModal';


const ImageGridContainer = (/*{ data, dispatch }*/) => {
  const { visibility, walletModalvisibility, metamaskModalVisibility, connectWalletModal } = useModal();
  const fetchData = async () => {
    try {
      //const response = await axios.get('https://example.com/api/data');
      // Dispatch an action to update the data in the Redux store
      //dispatch({ type: 'UPDATE_IMAGES', payload: [response.data] });
      
    } catch (error) {
      // Handle error
    }
  };

  const generateGenesisCardData = () => {
      var images = []
      for(var i = 0; i < 10; i++) {
        images.push(
          {title: "title#"+ i, url: "https://nft-images-bucket151605-dev.s3.amazonaws.com/" + i + ".png"}
          )
      }
      return images
  }

  const generateNascentCardData = () => {
    var images = []
    for(var i = 10; i < 20; i++) {
      images.push(
        {title: "title#"+ i, url: "https://nft-images-bucket151605-dev.s3.amazonaws.com/" + i + ".png"}
        )
    }
    return images
}

  const getTitles = () => {
    var titles = []
    for(var i = 0; i < 10; i++) {
      titles.push("title#" + i)
    }
    return titles
  }

  const  checkMetaMaskInstalled = () => {
     return isMetaMaskInstalled()
  }



  const executeClick = (number) => {
    
    console.log("Image grid clicked " + number)
  }
  
    if(checkMetaMaskInstalled() && isWalletConnected()) {
      return( 
          //  <p>test</p>
        //  <ImageGrid props={this.props.data}>
          <ImageGrid nascentCardData={generateNascentCardData()} genesisCardData={generateGenesisCardData()} titles={getTitles()} clickCallback={executeClick} />
          )
    } else {
      
      return (
        <Layout>
          <GlobalStyles />
          {/* {connectWalletModal && <ConnectWallet/> } */}
          {walletModalvisibility && <WalletModal />}

          <Header/>
          
        </Layout>
      )
    }
  
};



const mapStateToProps = (state) => {
  return {
    image: "state.example.data,"
  };
};

export default connect(mapStateToProps)(ImageGridContainer);