import styled, { keyframes } from "styled-components";
//import sectionBgImage from "../../../../assets/images/bg/h1_banner_bg.png";
//import sectionBgImage from "../../../../assets/images/bg/banner-bg4.jpg";
//import sectionBgImage from "../../../../assets/images/bg/backgroundv6.png";
//import sectionBgImage from "../../../../assets/images/bg/banner_bg_3.jpg";
import sectionBgImage from "../../../../assets/images/bg/backgroundv16.png";
const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const DashboardV1Wrapper = styled.section`
  min-height: 820px;
  width: 100%;

  .image-container {
    display: flex;
    height: 800px;
  }  

  h1 {
    color: white;
    
  }
  h3 {
    color: white;
  }
  .metadata {
    h1{
      color: white;
      font-family: eternal;
    }
  }
  
  h2 {
    color: white;
    font-family: eternal;
  }
  .row {
    margin-top: 200px;
  }
`;

export default DashboardV1Wrapper;
