import styled, { keyframes } from "styled-components";
//import sectionBgImage from "../../../../assets/images/bg/h1_banner_bg.png";
//import sectionBgImage from "../../../../assets/images/bg/banner-bg4.jpg";
//import sectionBgImage from "../../../../assets/images/bg/backgroundv6.png";
//import sectionBgImage from "../../../../assets/images/bg/banner_bg_3.jpg";
// import sectionBgImage from "../../../../assets/images/bg/banner_bg30.png";
// import sectionBgImageSmall from "../../../../assets/images/bg/banner_bg_smaller8.png";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const ImageGridStyle = styled.section`
    min-height: 820px;
    width: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 90px;

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
      }
      
      .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

    .image-grid {
        padding: 30px;
        display: 'inline-block',
        overflowX: 'scroll'
    }
    .card {
        display:'inline-block';
        align-items: center;
        justify-content: center;
        background-color: #5171ba;
        margin: 5px;
        height: 180px;
        width: 180px;
        border-radius: 10px;
        border-color: white;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
        
        p {
            font-family: eternal;
        }
    }
    h1 {
       text-align: center;
       color: #FFFFFF;
    }
    h2 {
        color: #FFFFFF;
        font-family: 'eternal'
    }
    .card.active {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(0.95);
    }
    .card.selected {
        background-color: #32487b;
    }
    .card.available {
        background-color: white;
    }

    .image-container {
        position: relative;
        height: 600px;

        
    }
    .col-lg-6 {        
        background: #white;
        border-radius: 15px;
        
    }

    .row {
        margin-top: 50px;
    }

    .leftArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
    }

    .rightArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;

    }

    /* Style the scrollbar */
    div::-webkit-scrollbar {
        width: 12px; /* Set the width of the scrollbar */
    }

    /* Style the scrollbar thumb */
    div::-webkit-scrollbar-thumb {
        background: #32487b; /* Set the background color of the scrollbar thumb */
        border-radius: 10px; /* Set the border radius of the scrollbar thumb */
    }

    Button {
        margin-top: 10px;
    }

      
    
`;

export default ImageGridStyle;

