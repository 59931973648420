import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

//import characterThumb from "../../../../assets/images/nft/Character1.png";
//import characterThumb from "../../../../assets/images/nft/banner_character_thumb.png";
import characterThumb from "../../../../assets/images/nft/characterv3.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_strait.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
//import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useEffect, useState } from "react";
import { totalMintCount } from '../../../../utils/web3mint';
import { mintActive } from "../../../../config";

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [remaining, setRemaining] = useState(0);
  useEffect(() =>{
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      //console.log(totaltMintedItems);
      setRemaining(totaltMintedItems);
    }

    calculateRemainingItems();
  },[])
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        {/* <div className="row">
          
            <div className="col-lg-6">
            
            </div>
        </div> */}
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
