import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
import ImageGridStyle from "./imageGrid.style";
import GlobalStyles from "../../assets/styles/GlobalStyles";
import Layout from "../../common/layout";
import Header from "../section/header/v1/Header";
import { useEffect } from "react"
import HorizontalScroll from "./HorizontalScroll";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useModal } from "../../utils/ModalContext";
import Scroll from 'react-scroll-horizontal';
import Button from "../../common/button";
import ErrorModal from "../../common/modal/error/ErrorModal";
import { ColorRing } from "react-loader-spinner"
import left from "../../assets/images/icon/icons8-chevron-left-100.png"
import right from "../../assets/images/icon/icons8-chevron-right-100.png"
import metadata from '../../metadata.json'
import traitOrder from '../../traitOrder.json'
import { MdOutlineBorderLeft } from "react-icons/md";
import { FiPlus, FiX } from "react-icons/fi";
import { IconWrapper } from "../../common/accordion/Accordion.style";
import { CloseIcon } from "../../common/accordion/Accordion"
var curLevel = 0

const Game = (props) => {
  const { errorModalHandle, errorModalVisibility, loadingHandle } = useModal();
  //initialize state
  var availTraitsInit = props.availableTraitsForLevel(0, [])
  const [selections, setSelections] = useState({nascentCount: 0, genesisCount: 0})
  const [curSelections, setCurSelections] = useState([])
  const [availableTraits, setAvailableTraits] = useState([availTraitsInit])
  const [levelSelections, setLevelSelections] = useState({})
  const [scrollAmnt, setScrollAmnt] = useState(0)
  // const [curLevel, setCurLevel] = useState(0)
  const imgRef = useRef(null)
  const divRef = useRef(null);
  //console.log("curSelections: " + curSelections)
  //console.log("getLatestLevelForSelections(): " + getLatestLevelForSelections())
  //divRef.current.scrollTop = divRef.current.scrollHeight;

  useEffect(() => {
    //console.log("useEffect called")
    //for(var i = 0; i < divRef.current.scrollHeight; i++) {
      //console.log("useEffect() called")
      if(scrollAmnt < divRef.current.scrollHeight) {
        //divRef.current.scrollTop = scrollAmnt;
        //setScrollAmnt(scrollAmnt + 4)
      }
    //}
    
    //divRef.current.scrollIntoView({ behavior: 'smooth' });
    //console.log("divRef: " + divRef)
    });
  
    function LeftArrow() {
      const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);
    
      return (
        <div className="leftArrow" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
          <img src={left} />
        </div>
      );
    }
    
    function RightArrow() {
      const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    
      return (
        <div className="rightArrow" disabled={isLastItemVisible} onClick={() => {
          scrollNext()
          //console.log("scrollNext()")
        }}>
          <img src={right} />
        </div>
      );
    }

    function updateSelection(trait, level) {
      if(curSelections[level] == trait) { //case: user clicks the same trait
        var selectionsCopy = {...selections}
        selectionsCopy[trait] = false;
        setSelections(selectionsCopy)
        curSelections[level] = undefined;
        setCurSelections(curSelections)
        return
      }
      if(curSelections[level]) {
        selections[curSelections[level]] = false;
      }
      var curSelectionsCopy = [...curSelections]
      curSelectionsCopy[level] = trait
      setCurSelections(curSelectionsCopy)      
      setSelections({...selections, [trait]: true, [level]: true})
    }

    function getLatestLevelForSelections() {
      var level = 0;
      for(var i = 0; i < curSelections.length; i++) {
        //level
        if(curSelections[i] == undefined) return level
        level++
      }
      return level
    }

    // console.log("levelSelections: " + JSON.stringify(levelSelections))
    // console.log("levelSelections length: " + Object.keys(levelSelections).length)
    //loadingHandle()
  return (
    <Layout>
       <GlobalStyles />
      <ImageGridStyle> 
      <div className="container">
        { errorModalVisibility && <ErrorModal />}
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <div className="image-container" >            
            {/* <Canvas /> */}
            {/* <canvas id="canvas" ref={canvasRef} width="400px" height="400px" /> */}
                <img src="https://nft-images-bucket151605-dev.s3.amazonaws.com/public/images/background/default-background.png" style={{
                  borderRadius: '15px',
                  border: '10px solid white',
                  boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.3)',
                  position: 'absolute',
                  zIndex: 1,}}/>
                <img src="https://nft-images-bucket151605-dev.s3.amazonaws.com/public/images/character/default-character.png" style={{
                  borderRadius: '2%',
                  position: 'absolute',
                  zIndex: 1,}}/>
                  {
                    curSelections.map((trait,index) => {
                      
                      return <img src={"https://nft-images-bucket151605-dev.s3.amazonaws.com/public/" + trait + ""} style={{
                        borderRadius: '2%',
                        position: 'absolute',
                        zIndex: 2 + index,}}/>
                    })
                  }
            </div>
          </div>
          {/* <div className="col-sm-1"></div> */}
          <div  ref={divRef} className="col-lg-6" style={{
            maxWidth: '700px',
            maxHeight: '600px',
            overflow: 'scroll',
            background: "#1e7cc4",
            borderRadius: "15px",
            paddingLeft: "30px",
            paddingTop: "30px",
            border: "1px solid white"
            
          }}>
            {
              availableTraits.map((traitsArr, level) => {
                //console.log("availablTraits traitsArr: " + traitsArr)
                //style={{display: 'flex', flexDirection: 'row', width: '400px'}}
                return <div > 
                  <h2>{Object.keys(traitOrder)[level]}</h2>
                  <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                 {/* <div style={{display: 'inline-block', overflowX: 'scroll', whiteSpace: 'nowrap'}}> */}
                    {
                      traitsArr.map((trait,index) => {
                        return (
                          <div
                              className={`card ${selections[trait] ? 'selected' : ''}`}
                              style={{display: 'inline-block'}}
                              onClick={() => {
                                //updateSelection(trait, level)
                                if(availableTraits.length > level + 1) { //clicking on previous row  
                                  //console.log("availableTraits > level")
                                  var countAvailableToPop = availableTraits.length - level;
                                  var countCurSelectionsToPop = curSelections.length - level;
                                  var availableTraitsCopy = [...availableTraits]
                                  var curSelectionsCopy = [...curSelections]
                                  var selectionsCopy = {...selections}
                                  var levelSelectionsCopy = {...levelSelections}
                                  for(var i = 0; i < countAvailableToPop - 1; i++) {                                          
                                      //console.log("setting selections and levelSelections for level: " + availableTraitsCopy.length)
                                      availableTraitsCopy.pop()                                          
                                  }
                                  for(var i = 0; i < countCurSelectionsToPop; i++) {
                                    levelSelections[curSelectionsCopy.length - 1] = false
                                    selections[curSelectionsCopy[curSelectionsCopy.length -1]] = false
                                    curSelectionsCopy.pop()
                                  }
                                  if(!selections[trait]) {
                                    curSelectionsCopy.push(trait)
                                    selections[trait] = true
                                  }
                                  setAvailableTraits(availableTraitsCopy)
                                  setCurSelections(curSelectionsCopy)
                                  setSelections(selections)
                                  setLevelSelections({...levelSelections, [level]: true})
                                  return
                                }
                                  if(selections[trait]) {
                                    //console.log("availableTraits.length: " + availableTraits.length)
                                    //console.log("level:" + level)

                                    var curSelectionsCopy = [...curSelections]
                                    curSelectionsCopy.pop()
                                    setSelections({...selections, [trait]: false, [level]: true})
                                    setLevelSelections({...levelSelections, [level]: false})
                                    setCurSelections(curSelectionsCopy)
                                    return
                                  }
                                  // if(levelSelections[level]) {
                                  //   console.log("levelSelections[level]: " + levelSelections[level])
                                  //   console.log("level: " + level)
                                  //   return
                                  // }
                                  var selectionsCopy = {...selections}
                                  var curSelectionsCopy = [...curSelections]
                                  selectionsCopy[curSelections[level]] = false
                                  setSelections({...selectionsCopy, [trait]: true, [level]: true})
                                  if(level < curSelections.length) {
                                    curSelectionsCopy[level] = trait
                                  } else {
                                    curSelectionsCopy.push(trait)
                                  }
                                  setCurSelections(curSelectionsCopy)
                                  setLevelSelections({...levelSelections, [level]: true})                                                                    
                              }
                          }
                          >
                              <p>{props.getNameOfTraitFromPath(trait)}</p>
                              <p>{"Rarity: " + props.getCntForTrait(props.getNameOfTraitFromPath(trait)) + "/" + "3025"}</p>
                              {/* <p>
                                {props.getCntForTrait(trait.split("/")[2].split(".")[0]) + "/" + "totalNumberOfTraits"}
                              </p>                               */}
                          </div>
                  )
                      })
                    }
                {/* </div> */}
                </ScrollMenu></div>

              })
              
            }
            {
              levelSelections[Object.keys(availableTraits).length - 1] == true && curSelections.length < props.numOfTraits ?
            <Button lg variant="outline" onClick={() => {
              //("next clicked")
              //console.log("curLevel: " + curSelections.length)
              //console.log("curSelections: " + curSelections)
              //console.log("divRef: " + divRef)
              //divRef.current.scrollIntoView({ behavior: 'smooth' });
              if(curSelections.length >= props.numOfTraits) return
              var availableTraitsNextLevel = props.availableTraitsForLevel(curSelections.length, curSelections)
              //console.log("availableTraitsNextLevel from next: " + availableTraitsNextLevel)
              //curLevel = curLevel + 1
              //availableTraits.push(availableTraitsNextLevel)
              var availableTraitsNew = [...availableTraits]
              availableTraitsNew.push(availableTraitsNextLevel)
              setAvailableTraits(availableTraitsNew)
            }} >
              Add Trait
              
            </Button>
            : <div></div>
            }
          </div>
        </div>
        <div className="row">
        {curSelections.length == props.numOfTraits ? 
          <Button lg variant="mint" onClick={() => {
              loadingHandle()
              props.saveTraitsToDB(curSelections).then((res) => {
                loadingHandle()
                if(resObj?.code == 200 || resObj == undefined) {
                  //navigate to info
                  props.setNav("DASHBOARD")
                }
                //console.log("response from save: " + JSON.stringify(res))
                //console.log("res.status: " + )
                var resObj = JSON.parse(JSON.stringify(res))
                if((resObj.status && resObj.status != 200) || resObj?.error) {
                  errorModalHandle("there was a problem claiming this NFT. Please refresh your browser and try again")
                  loadingHandle()
                }
              })
            }}>
                {/* {" "} */}
                Claim this NFT and WhitelList
          </Button> : <div></div>
        }
        </div>
      </div>
      </ImageGridStyle>
    </Layout>
  );
};

export default Game;