import styled from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/header_bg8.png";

const NavWrapper = styled.nav`
  
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  z-index: 999;
  h1 {
    color: white;
  }
  .card {
    positon: relative;
    align-items: center;
    justify-content: center;
    background-color: #5171ba;
    height: 200px;
    width: 350px;
    border-radius: 10px;
    border-color: white;
    border-width: 2px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    

}
  
  &.bithu_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 1px;
    transition: all 0.3s;
    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      background: rgb(1,1,1,.3);
      height: 90px;
      background-size: cover;
      backdrop-filter: blur(15px);
      z-index: 1000;
      margin-top: 0px;
      transition: all 0.1s;
    h1 {
      position: relative;
      font-size: 50px;
      font-family: eternal;
      text-align: center;
      padding-top: 50px;
      color: #102447;
      &.sticky {
        color: white;
      }
    }
   
  }

  .bithu_menu_sect {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bithu_menu_left_sect {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    
    .logo {
      a {
        display: inline-block;
      }
    }
  }

  .bithu_menu_right_sect {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .bithu_menu_list {
    margin-left: 65px;
    margin-right: 96px;
    max-width: 514px;
    min-width: 409px;
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        cursor: pointer;

        a {
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #5a1d60;
        }

        &:hover {
          a {
            color: #00ffa3;
          }
        }

        /* submenu */
        &.submenu {
          .sub_menu_sect {
            background: transparent;
            border-top: 50px solid transparent;
            position: absolute;
            top: -50px;
            left: -20px;
            width: 190px;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
            transition: all 0.5s;

            .sub_menu_list {
              padding: 15px 20px;
              background: #171f25;
              flex-wrap: wrap;
              li {
                a {
                  font-family: "Bakbak One";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 40px;
                  color: rgba(255, 255, 255, 0.8);
                  text-transform: capitalize;
                }

                &:hover {
                  a {
                    color: #00ffa3;
                  }
                }
              }
            }
          }

          &:hover {
            .sub_menu_sect {
              top: 7px;
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }

  .bithu_menu_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    min-width: 284px;
    button {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Bakbak One";
      font-weight: 400;
      font-size: 16px;
    }

    .menu_btn {
      display: none;
      border: none;
      background: transparent;
      cursor: pointer;
      svg {
        font-size: 40px;
      }
    }

    .join_btn {
      height: 50px;
      width: 114px;
      background-color: #4b2bc0;
    }

    .connect_btn {
      height: 50px;
      min-width: 150px;
      border: none;
      margin-left: 20px;
      background: rgba(75, 43, 192, 0.5);

      svg {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    .bithu_menu_list {
      margin-right: 20px;
    }
  }
  @media (max-width: 991px) {
    &.bithu_header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: 1px;
      height: 200px;
      transition: all 0.3s;
      h1 {
        position: relative;
        font-size: 80px;
        font-family: eternal;
        text-align: center;
        padding-top: 50px;
        color: white;
        border: none;
        &.sticky {
          color: white;
        }
      }
      &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background: rgb(1,1,1,.3);
        height: 90px;
        //background-size: cover;
        backdrop-filter: blur(15px);
        z-index: 1000;
        margin-top: 0px;
        transition: all 0.1s;
        
        h1 {
          position: relative;
          font-size: 0px;
          padding-top: 80px;
          color: white;
          -webkit-text-stroke: 2px black;
          &.sticky {
            color: white;
          }
        }
      }
    }
    .bithu_menu_right_sect {
      justify-content: end;
    }
    .bithu_menu_btns {
      justify-content: end;
      .menu_btn {
        display: block;
      }
    }

    .bithu_menu_btns {
      .join_btn {
        display: none;
      }
    }
    .bithu_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (max-width: 667px) {
    .bithu_menu_btns {
      .connect_btn {
        display: none;
      }

      .menu_btn {
        svg {
          font-size: 30px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .bithu_menu_left_sect {
      width: 180px;
      .logo {
        img {
          width: 100px;
        }
      }
    }

    .bithu_menu_right_sect {
      width: 50%;
      .bithu_menu_right_sect {
        width: 50%;
      }
    }
  }
`;

export default NavWrapper;
