import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import LoadingModalStyleWrapper from "./LoadingModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import hoverShapeSmall from "../../../assets/images/icon/hov_shape_s.svg";
import data from "../../../assets/data/socialShare";
import { ColorRing } from 'react-loader-spinner'
const LoadingModal = () => {
  const { errorModalHandle } = useModal();
  return (
    <>
      <LoadingModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
          <ColorRing
              visible={true}
              height="200"
              width="200"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
          </div>
        </div>
      </LoadingModalStyleWrapper>
    </>
  );
};

export default LoadingModal;
