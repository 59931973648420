import {useModal} from "../utils/ModalContext"
import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/v2/Header";
import Banner from "../components/section/banner/v1";
import CharacterSlider from "../components/section/characterSlider/v3";
import About from "../components/section/about/v3";
import HowToMint from "../components/section/howToMint/v2";
import RoadMap from "../components/section/roadMap/v3";
import Team from "../components/section/team/v3";
import FAQ from "../components/section/faq/v3";
import NewsLetter from "../components/section/newsLetter";
import CTA from "../components/section/cta/v1";
import Footer from "../components/section/footer/v3";
import MintNowModal from "../common/modal/mintNowModal" 
import WalletModal from "../common/modal/walletModal/WalletModal" ;
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";
import Info from "../components/section/info/v2/Info";
import Notification from "../components/section/notification/v2/Notification"
import GameContainer from "../components/gameComponent2/GameContainer";
import ErrorModal from "../common/modal/error/ErrorModal";  
import { Analytics } from 'aws-amplify';


const HomeV4 = () => {
  const { visibility, walletModalvisibility, metamaskModalVisibility, connectWalletModal, account, errorModalVisibility, errorMessage } = useModal();
  Analytics.record({ name: 'homePageVisited' });
  if(!account) {
    Analytics.record({ name: 'accountConnected' });

      return(
        <Layout>
        <GlobalStyles />
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        {metamaskModalVisibility && <MetamaskModal/> }
        {connectWalletModal && <ConnectWallet/> }
        <Header />
        <Banner />
        <Info />
        <CTA />
        {/* <RoadMap /> */}
        {/* <Notification info="Please connect your wallet"/> */}
        </Layout>
      )
  }
  return (
    <> 
      <Layout>
        <GlobalStyles />
        {visibility && <MintNowModal />}
        {walletModalvisibility && <WalletModal />}
        {metamaskModalVisibility && <MetamaskModal/> }
        {connectWalletModal && <ConnectWallet/> }
        {errorModalVisibility && <ErrorModal message={errorMessage} />}
        <Header />
        <GameContainer />
        <CTA />
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

export default HomeV4;
