
import styled, { keyframes } from "styled-components";
//import sectionBgImage from "../../../../assets/images/bg/h1_banner_bg.png";
//import sectionBgImage from "../../../../assets/images/bg/banner-bg4.jpg";
//import sectionBgImage from "../../../../assets/images/bg/backgroundv6.png";
//import sectionBgImage from "../../../../assets/images/bg/banner_bg_3.jpg";
// import sectionBgImage from "../../../../assets/images/bg/banner_bg30.png";
// import sectionBgImageSmall from "../../../../assets/images/bg/banner_bg_smaller8.png";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const ImageGridStyle = styled.section`
    min-height: 820px;
    width: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 90px;

    .image-grid {
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10px;
    }
    .card {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }
    h1 {
       text-align: center;
       color: #FFFFFF;
    }
    .card.active {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(0.95);
    }
    .card.selected {
        background-color: blue;
    }
`;

export default ImageGridStyle;

