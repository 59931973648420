import { connect } from 'react-redux';
import { useEffect, useState } from 'react'
import axios from 'axios';
//import ImageGrid from './imageGrid'
import Game from './Game'
//import { isMetaMaskInstalled, ethereum, maxMintsPerUser, isWalletConnected } from '../../config';
import { useModal } from "../../utils/ModalContext";

import { Amplify, Storage, API } from 'aws-amplify';
import awsconfig from '../../aws-exports'

import Header from '../section/header/v2/Header';
import GlobalStyles from '../../assets/styles/GlobalStyles';
import Layout from '../../common/layout';
//import { getCurrentUserAcct } from '../../utils/web3mint'
//import { connectAccount } from '../../config'
import MintNowModal from "../../common/modal/mintNowModal";
import WalletModal from '../../common/modal/walletModal/WalletModal'//"/common/modal/walletModal/WalletModal";
import MetamaskModal from "../../common/modal/metamask/MetamaskModal";
import ConnectWallet from '../../common/modal/metamask/ConnectWallet';
import metadata from "../../metadata.json"
import traitOrder from "../../traitOrder.json"
import Info from '../../components/section/info/v2/Info'
import LoadingModal from '../../common/modal/loading/LoadingModal';
import Dashboard from '../section/dashboard/v1';
//import { Account } from 'aws-sdk';
//import { getCurrentUserAcct } from '../../utils/web3mint'
var lambda_url = "https://h3bnq4adkbkwnjg3rlkbybn3cy0srzpk.lambda-url.us-east-1.on.aws/"

var usedTraits = []
var usedTraitsSet = new Set()

const traits = []
var numOfTraits = 0//traits.length
const availableTraits = []
const traitsNames = []
var generatedTraits = []
var generatedTraitsSet = new Set()
var mapTraitCnts = new Map()

Amplify.configure(awsconfig);
API.configure(awsconfig);
const awsAPIGatewayKey = process.env?.REACT_APP_AWS_GATEWAY_API_KEY
const awsApiGatewayName = process.env?.REACT_APP_AWS_GATEWAY_API_NAME
const GameContainer = (/*{ data, dispatch }*/) => {
    const [loading, setLoading] = useState(true)
    const { walletModalvisibility } = useModal()
    const { account, loadingHandle, isLoading, errorModalHandle, errorMessage } = useModal()
    const [claimedTraits, setClaimedTraits] = useState([])
    const [navigation, setNavigation] = useState({})
    //const [reload, setReload] = useState(false)
    var reload = false;
    useEffect(() => {
        const initialize = async () => {    
            if(account && claimedTraits.length == 0) {
                //console.log("account: " + account)
                //const getTraitsFun = async () => {
                //console.log("getClaimedTraits")
                var traits = await getTraitsForAccount(account)
                setClaimedTraits(traits)
                //}
                //getTraitsFun()
                //claimedTraits = [1,2,3]//traits
            }        
            //console.log("initialize()")
            //loadingHandle()
            await getS3TraitImages2()
            usedTraits = await fetchSavedTraits()
            usedTraits.forEach((traits) => {
                //console.log("used traits: " + traits)
                usedTraitsSet.add(JSON.stringify(traits))
            })
            generatedTraits = getGeneratedTraits()
            //console.log("generatedTraits: " + JSON.stringify(generatedTraits))
            generatedTraits.forEach((trait) => {
                generatedTraitsSet.add(JSON.stringify(trait))
            })
            setLoading(false)
            //console.log("usedTraits: " + usedTraits)
            getTraitCnts()
            //loadingHandle()
        }

        initialize();
        },[]);
      
        async function saveTraitsToDB(curSelections) {
            //console.log("amplify credentials: " + Amplify.Credentials)
            //console.log(curSelections)
            //var ethAccount = await getCurrentUserAcct()
            //console.log("ethAccount:" + ethAccount)
            //console.log("account from modal: " + account)
            var params = {
                account: account.toString(),
                head: curSelections[0],
                eyes: curSelections[1],
                mouth: curSelections[2],
                suit: curSelections[3]
              };
            
            try {
                // const response = await axios.post(lambda_url, params, {
                //     headers: {
                //       // 'application/json' is the modern content-type for JSON, but some
                //       // older servers may use 'text/json'.
                //       // See: http://bit.ly/text-json
                //       'content-type': 'application/json'
                //     }
                // });
                
                const response = await API.post(awsApiGatewayName, "/traitsDB", {
                    headers: {
                      //🚨 don't embed api keys in real life!!
                      "x-api-key": awsAPIGatewayKey,
                    },
                    body: params
                    })
                const data = response?.data;
                
                //console.log("saveTraitsToDB() response: " + JSON.stringify(response));
                //console.log(JSON.stringify(data));
                return data
              } catch (error) {
                //console.error(error);

                return error
              }
            
        }

        async function fetchSavedTraits() {
            //fetch the existing trait combos from dynamoDB
            //and store them inside used traits variable
            //these will be used when parsing the combinations tree
            //will do a scan operation on dynamo db for all trait combos that are not expired
            //can call existing lambda function to retreive these using get
            /*const params = {
              head: "head trait",
              eyes: "eyes trait",
              mouth: "mouth trait",
              suit: "suit trait"
            };*/
            var savedTraits = []
            try {
                //const response = await axios.get(lambda_url);
                const response = await API.get(awsApiGatewayName, "/traitsDB", {
                    headers: {
                      //🚨 don't embed api keys in real life!!
                      "x-api-key": awsAPIGatewayKey,
                    },
                    })
                const data = response.data;
                //console.log("fetch saved traits: " + JSON.stringify(response));
                var body = response//JSON.parse(response)
                body.Items.forEach((item) => {
                    var used = new Array(numOfTraits)
                    for (const [key, value] of Object.entries(traitOrder)) {
                        //console.log(`${key}: ${value}`);
                        for (const [key1, value2] of Object.entries(item)) {
                            if(key1.includes(key)) {
                                used[traitOrder[key]] = item[key1]
                            }
                        }
                    }
                      savedTraits.push(used)
                    // var used = [item.head_trait, item.eyes_trait, item.mouth_trait, item.suit_trait]
                    // savedTraits.push(used)
                })
              } catch (error) {
                console.error("fetchSavedTraits()" + error);
              }
              return savedTraits
              //console.log("usedTraits: " + JSON.stringify(usedTraits))
        }

        function getGeneratedTraits() {
            var generatedTraits = []
            for(var i = 0; i < Object.keys(metadata).length; i++) {
                var traitsArr = new Array(numOfTraits)
                for(var j = 0; j < metadata[i].attributes.length; j++ ) {
                    for (const [key, value] of Object.entries(traitOrder)) {
                        if(metadata[i].attributes[j].trait_type.includes(key)) {
                            var path = "images/" + metadata[i].attributes[j].trait_type + "/"
                            //traitsArr.push(path + metadata[i].attributes[j].value + ".png")
                            traitsArr[traitOrder[key]] = path + metadata[i].attributes[j].value + ".png"
                        }
                    }
                }
                generatedTraits.push(traitsArr)
            }
            return generatedTraits;
        }

        async function getTraitsForAccount() {
            var claimedTraits = []
            //console.log("amplify credentials: " + Amplify.Credentials)
            try {
                const response = await API.get(awsApiGatewayName, "/traitsDB", {
                    headers: {
                      //🚨 don't embed api keys in real life!!
                      "x-api-key": awsAPIGatewayKey,
                    },
                    queryStringParameters: {
                        ethAccount: account.toString(),
                    },
                    })
                // const response = await axios.get(lambda_url, {
                //     params: {
                //       ethAccount: account.toString(),
                //     },
                // });
                //("getTraitsForAccount() response: " + JSON.stringify(response))
                // for( const[key, val] in Object.entries(response)) {
                //     console.log("getTraitsForAccount() property: " + key)
                // }
                const resObj = JSON.parse(JSON.stringify(response))//JSON.parse(response.data)
                //console.log("getTraitsForAccount resObj: " + resObj)
                if(resObj?.status == 400) {
                    //console.log("getTraitsForAccount status " + resObj.status)
                    errorModalHandle(errorMessage)
                    return;
                }
                for (const [key, value] of Object.entries(traitOrder)) {
                    //console.log("getTraitsForAccount key: " + key)
                    for (const [key1, value1] of Object.entries(resObj.Item)) {
                        // console.log("getTraitsForAccount key1: " + key1)
                        // console.log("getTraitsForAccount value1: " + value1)
                        if(key1.includes(key)) {
                            claimedTraits.push(value1)
                        }
                    }
                    //data.body
                    //{"body":"{\"Item\":{\"mouth_trait\":\"images/mouth/filtration-system.png\",\"eth_account\":\"0xb48d0e729af1bf233ef69f046b85fdfb212dfc55\",\"suit_trait\":\"images/suit/leather-jacket.png\",\"did_mint\":false,\"date_claimed\":1671843355397,\"eyes_trait\":\"images/eyes/eye-patch.png\",\"head_trait\":\"images/head/afro.png\"}}"}
                }
                //console.log("getTraitsForAccount(), " + account.toString() + " response: " + resObj);
                //return data
              } catch (error) {
                //console.error("getTraitsForAccount error: " + error);
                return error
              }
              //console.log("getTraitsForAccount claimedTraits: " + claimedTraits)
              return claimedTraits
        }

        function getTraitCnts() {
            for(var i = 0; i < Object.keys(metadata).length; i++) {
                //console.log("getTraitCnts" + "metadata" + JSON.stringify(metadata[i].attributes))
                for(var j = 0; j < metadata[i].attributes.length; j++) {
                    if(mapTraitCnts.has(metadata[i].attributes[j].value)) {
                        var cntForTrait = mapTraitCnts.get(metadata[i].attributes[j].value)
                        mapTraitCnts.set(metadata[i].attributes[j].value, cntForTrait + 1)
                    } else {
                        mapTraitCnts.set(metadata[i].attributes[j].value, 1)
                        
                    }
                }
            }
            mapTraitCnts.forEach(function(value, key) {
                //console.log("getTraitCnts: " + key + " = " + value);
            })
        }


        function getNameOfTraitFromPath(traitPath) {
            var dirNames = traitPath.split("/")
            var traitName = ""
            if(dirNames.length > 1) {
                traitName = dirNames[2].substr(0, dirNames[2].length - 4)
            }
            return traitName;

        }

        function getCntForTrait(trait) {
            //console.log("cnts map: " + JSON.stringify(mapTraitCnts))
            //console.log("afro cnt: " + mapTraitCnts.get("afro"))
            //console.log("getCntForTrait: " + trait)
            //console.log("cnt: " + mapTraitCnts.get(trait))
            return mapTraitCnts.get(trait);
        }

        function setNav(destination) {
            setNavigation({...navigation, nav: destination})
            reload = true;
        }
    

    if(navigation.nav == "DASHBOARD") {
        return <Dashboard getNameOfTraitFromPath={getNameOfTraitFromPath} mapTraitCnts={mapTraitCnts} getTraitsForAccount={getTraitsForAccount} claimedTraits={claimedTraits}/>
    }
    //console.log("account from modal: " + account)
    //loadingHandle()
    if(loading) {
        return <div><h1>Loading...</h1></div>
    }
    if(claimedTraits.length > 1) {
        return <Dashboard getNameOfTraitFromPath={getNameOfTraitFromPath} mapTraitCnts={mapTraitCnts} getTraitsForAccount={getTraitsForAccount} claimedTraits={claimedTraits}/>
    }
    //console.log("isMetaMaskInstalled(): " + isMetaMaskInstalled())
    //console.log("isWalletConnected(): " + isWalletConnected())
    //if(isMetaMaskInstalled() && isWalletConnected()) {
        return(
            
            <Layout>
            <GlobalStyles />
                {/* {visibility && <MintNowModal />} */}
                {walletModalvisibility && <WalletModal />}
                {/* {metamaskModalVisibility && <MetamaskModal/> } */}
                {/* {connectWalletModal && <ConnectWallet/> } */}
                {isLoading && <LoadingModal />}
            {/* <Header /> */}
            <Game availableTraitsForLevel={getAvailableTraitsForCurrentLevel} 
            traitsProp={traits}
            availableTraits={availableTraits} 
            numOfTraits={numOfTraits}
            fetchS3TraitImages={getS3TraitImages2}
            fetchSavedTraits={fetchSavedTraits}
            saveTraitsToDB={saveTraitsToDB}
            getCntForTrait={getCntForTrait}
            setNav={setNav}
            getNameOfTraitFromPath={getNameOfTraitFromPath}
            />
            </Layout>
            )
    //}  
};

function countNumberOfTraitsFromS3(storageItems) {
    var set = new Set()
    storageItems.results.forEach((traitResult) => {
        var directoryNameArr = traitResult.key.split("/")
        set.add(directoryNameArr[1])
    })
    
    return set.size
}

async function getS3TraitImages() {
    
    var images = []
    var storageItems = await Storage.list('images', 100)
    var traitsCount = countNumberOfTraitsFromS3(storageItems) - 2 // - 2 to remove character and background
    numOfTraits = traitsCount
    //console.log("traitsCount: " + traitsCount)
    //console.log(traits.)
    for(var i = 0; i < traitsCount; i++) {
        traits.push([])
    }
    //console.log("storage items: " + JSON.stringify(storageItems));
    storageItems.results.forEach((traitResult) => {
        //console.log("trait key: " + traitResult.key)
        var traitResultDirNames = traitResult.key.split("/")
        if(traitResultDirNames[1].includes("head")) {
            traits[0].push(traitResult.key)
        }
        if(traitResultDirNames[1].includes("eyes")) {
            traits[1].push(traitResult.key)
        }
        if(traitResultDirNames[1].includes("mouth")) {
            traits[2].push(traitResult.key)
        }
        if(traitResultDirNames[1].includes("suit")) {
            traits[3].push(traitResult.key)
        }
    })
    //console.log("traits: " + traits)
    //var storageItems = await Storage.get('')
    //console.log(JSON.stringify(storageItems))
    /*for(var i = 0; i < traits.length; i++) {
        var urls = await Storage.list("/", 10)
        // urls.forEach((url) => {
        //     console.log(url)
        //     images.push(url)
        // })
        var url = await Storage.get("0.png")
        images.push(url)
    }*/
    return images
}

async function getS3TraitImages2() {
    
    var images = []
    var storageItems = await Storage.list('images', 100)
    var traitsCount = countNumberOfTraitsFromS3(storageItems) - 2 // - 2 to remove character and background
    numOfTraits = traitsCount
    //console.log("traitsCount: " + traitsCount)
    //console.log(traits.)
    for(var i = 0; i < traitsCount; i++) {
        traits.push([])
    }
    var setTraitCategories = new Set()
    var map = new Map()
    //console.log("storage items: " + JSON.stringify(storageItems));
    storageItems.results.forEach((traitResult) => {
        //console.log("trait key: " + traitResult.key)
        var traitResultDirNames = traitResult.key.split("/")
        if(!(traitResultDirNames[1] == 'background' || traitResultDirNames[1] == 'character')) {
            //console.log(traitResultDirNames[1])
            setTraitCategories.add(traitResultDirNames[1])
            //map[traitResultDirNames[1]] = []
        }
    })
    //console.log("setTraitCategories: " + JSON.stringify(Array.from(setTraitCategories)))

    for(var i = 0; i < storageItems.results.length; i++) {
        //console.log("storageItems[i]: " + JSON.stringify(storageItems.results[i]))
        Array.from(setTraitCategories).forEach((traitCategory, index) => {
            // console.log("set index: " + index)
            // console.log("storageItems[i][1]: " + storageItems.results[i][1])
            // console.log("traitCategory " + traitCategory)
            if(storageItems.results[i].key.includes("/" + traitCategory)) {
                traits[traitOrder[traitCategory]].push(storageItems.results[i].key)
                //map[traitCategory].push(storageItems.results[i].key)
            }
        })
    }
    //console.log("traits map: " + JSON.stringify(map))
    // await setTraitCategories.forEach(async (traitCategory, index) => {
    //     var path = 'image/' + traitCategory + ""
    //     console.log("S3 list items from: " + path)
    //     var traitsInCategory = await Storage.list(path)
    //     traitsInCategory.results.forEach((trait)=> {
    //         traits[index].push(trait.key)
    //     })
        
    // })
    
    //console.log("traits: " + traits)
    //var storageItems = await Storage.get('')
    //console.log(JSON.stringify(storageItems))
    /*for(var i = 0; i < traits.length; i++) {
        var urls = await Storage.list("/", 10)
        // urls.forEach((url) => {
        //     console.log(url)
        //     images.push(url)
        // })
        var url = await Storage.get("0.png")
        images.push(url)
    }*/
    return images
}

function generateCountsOfEachTraitEvenDistr(totalUniqueNFTs) {
    //generate all traits metadata first - use combined option in nft-generate
    //then add them to a gerated arr of arr
    //when going through tree generate available traits for only those that exist in generated arr of arr
    //the next level of the tree should match the prefix for existing traits. easier to get a count that way maybe
    //can import a json file metadata with all the possible nfts 
    //100
    var counts = [[]]
    traits.forEach((traitArr) => {
        var cntFirstNminus1Traits =  totalUniqueNFTs/ traitArr.length
        var remainder = totalUniqueNFTs % traitArr.length;
        var countsArr = []
        for(var i = 0; i < traitArr.length - 1; i++) {
            countsArr.push(cntFirstNminus1Traits)
        }
        countsArr.push(cntFirstNminus1Traits + remainder)
        counts.push(countsArr)
    })
    return counts;
}

function getAvailableTraitsForCurrentLevel(level, curSelection) {
    var availableTraits = []
    //console.log("getAvailableTraitsForCurrentLevel() traits[level]: " + traits[level])
    for(var i = 0; i < traits[level].length; i++) {
        curSelection.push(traits[level][i])
        var isAvailable = recurseTree(level + 1, curSelection)
        curSelection.pop()
        if(isAvailable) {
            availableTraits.push(traits[level][i])
        }
    }
    return availableTraits
}

function recurseTree(level, curSelection) {
    if(level == numOfTraits) {
        if (usedTraitsSet.has(JSON.stringify(curSelection)) || !generatedTraitsSet.has(JSON.stringify(curSelection))) {
            return false
        } else {
            return true
        }
    }
    var isAvailable = false
    for(var i = 0; i < traits[level].length; i++) {
        curSelection.push(traits[level][i])
        isAvailable = recurseTree(level + 1, curSelection)
        curSelection.pop()
        if(isAvailable) return isAvailable
    }
    return false
}


const mapStateToProps = (state) => {
  return {
    image: "state.example.data,"
  };
};

export default GameContainer//connect(mapStateToProps)(GameContainer);