import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
  FaEthereum
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
 /* {
    thumb: openseaIcon,
    url: "#",
  },*/
  // {
  //   icon: <FaTwitter />,
  //   url: "https://twitter.com/citizen_3025",
  // },
  /*{
    icon: <FaLinkedinIn />,
    url: "#",
  },
  {
    icon: <FaFacebook />,
    url: "#",
  },*/
  // {
  //   icon: <FaInstagram />,
  //   url: "#",
  // },
  // {
  //   icon: <FaEthereum />,
  //   url: "https://etherscan.io/address/0xb0419fD582BdD9Cf7F2ac8058d808Bb40c23989B"
  // }//,
  /*{
    icon: <FaTelegramPlane />,
    url: "#",
  },
  {
    thumb: mediumIcon,
    url: "#",
  },*/
];

export default data;
