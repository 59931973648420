/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "metadata",
            "endpoint": "https://lwu4w36yd6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "api97099760",
            "endpoint": "https://mym44bsms7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "canonicalTraitsDB",
            "endpoint": "https://ocez9unmia.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e226c9de-d90d-4fa5-99c1-d30f14bfd3f5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yIvezoyom",
    "aws_user_pools_web_client_id": "4i55qcgc5caepo46qq4hodb5nn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "nft-images-bucket151605-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "traits_table-dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "78bf04c6d26b4c31b2a48e911eb7ff62",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
