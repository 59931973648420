import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import HomeV4 from "../pages/homeV4";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import Confirmation from "../pages/confirmation";
import { isMintAvailableForUser } from "../utils/web3mint";
import { useEffect } from "react";
import ImageGridContainer from "../components/gameComponent/imageGridContainer";
import GameContainer from "../components/gameComponent2/GameContainer";
//import ImageGrid from "../components/gameComponent/imageGrid";
// const { Amplify, Storage } = require('aws-amplify');
// const awsconfig = require('../aws-exports');
// Amplify.configure(awsconfig)
//import dotenv from 'dotenv';
import ReactGA from 'react-ga'

import { Amplify, Storage, API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import TermsAndConditions from "../components/section/terms/v1";

//dotenv.config();
Amplify.configure(awsconfig);

API.get("api97099760", "/traitsDB", {
  headers: {
    //🚨 don't embed api keys in real life!!
    "x-api-key": "xYCseN5rfi6Czy7WjPmsK66YTUvWxXkA2p6xzA3A",
  }})
       .then(response => {
         //console.log(response)

       })
       .catch(error => {
         //console.log(error)
       })

function App() {
  //config.isMintAvailableForUser = isMintAvailableForUser()
  // useEffect(() => {
  //   async function fetchData() {
  //     console.log(awsconfig)
  //     const image = await Storage.get("characterv4.png")
  //     console.log(image)
  //   }
  //   fetchData()
  // })
  //console.log(Storage.get("0.png"))
  return (
    <Routes>
      <Route path="/" element={<HomeV4/>} exact />
      <Route path="/game" element={<GameContainer />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      {/* <Route path="/home-two" element={<HomeV2 />} />
      <Route path="/home-three" element={<HomeV3 />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/post" element={<BlogDetails />} />
      <Route path="/confirmation" element={<Confirmation /> } /> */}
    </Routes>
  );
}

export default App;
