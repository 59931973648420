import styled, { keyframes } from "styled-components";
//import sectionBgImage from "../../../../assets/images/bg/h1_banner_bg.png";
//import sectionBgImage from "../../../../assets/images/bg/banner-bg4.jpg";
//import sectionBgImage from "../../../../assets/images/bg/backgroundv6.png";
//import sectionBgImage from "../../../../assets/images/bg/banner_bg_3.jpg";
import sectionBgImage from "../../../../assets/images/bg/backgroundv16.png";
const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const BannerV1Wrapper = styled.section`
  min-height: 50px;
  width: 100%;
  padding-top: 0px;

  .mint_live_circle_sect_frame {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    .mint_live_circle_sect {
      
      align-items: center;
      justify-content: center;
      height: 140px;
      width: 140px;
      border-radius: 50%;
      border: none;
      background: #47d1e3;
      position: relative;
      z-index: 1;
  
      .mint_live_circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
  
        .mint_live_text {
          animation: ${rotate} 20s linear infinite;
          position: absolute;
        }
      }
    }
  }

  .image_instructions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .image {
      width: 700px;
    }
  }
  .container {
    h1 {
      font-size: 40px;
      padding-top: 20px;
      color: #ffffff;
      font-family: "eternal";
    }

    h2 {
      padding-right: 140px;
      padding-left: 140px;
      color: #ffffff;
      font-family: "eternal";
      font-size: 50px;

    }
    h3 {
      padding-right: 200px;
      padding-left: 200px;
      color: #ffffff;
      font-family: "Mukta";

    }
    h4 {
      padding-right: 200px;
      padding-left: 200px;
      color: #ffffff;
      font-family: "Mukta";
    }
    
  }
  .buttons_frame {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 20px;
    
  }
  
  .bithu_v1_baner_left {
    margin-top: 66px;
    max-width: 500px;
    width: 100%;
    min-height: 550px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    h2 {
      font-family: "Mukta";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      color: #7f4080;
      margin-bottom: 32px;
    }

    h3 {
      font-family: "Mukta";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      justify-contents: center
      margin-top: 58px;
      margin-bottom: 58px;
    }

    .coin-info {
      display: flex;
      flex-direction: column;
      margin-top: 37px;
      span {
        font-family: "Bakbak One";
        font-size: 18px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.8);

        & .highlighted {
          color: #ffe600;
        }
      }
    }
  }

  .bithu_v1_baner_right {
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-top: 66px;

    .mint_live_circle_sect {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
      width: 140px;
      border-radius: 50%;
      border: none;
      background: #d64df5;
      position: absolute;
      top: -65px;
      left: -65px;
      z-index: 1;

      .mint_live_circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .mint_live_text {
          animation: ${rotate} 20s linear infinite;
          position: absolute;
        }
      }
    }

    .bithu_v1_baner_right_img_sect {
      position: relative;
      width: 100%;
      height: 550px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bithu_v1_baner_right_img_bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .bithu_v1_baner_right_img {
        position: relative;
        z-index: 1;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .bithu_v1_baner_left {
      h2 {
        font-size: 50px;
        line-height: 65px;
      }

      h3 {
        font-size: 34px;
        line-height: 38px;
      }

      .baner_buttons {
        margin-top: 40px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .container {
      h1 {
        padding-top: 20px;
        color: #ffffff;
        font-family: "eternal";
      }
  
      h2 {
        padding-right: 0px;
        padding-left: 0px;
        color: #ffffff;
        font-family: "eternal";
  
      }
      h3 {
        padding-right: 0px;
        padding-left: 0px;
        color: #ffffff;
        font-family: "Mukta";
  
      }
      h4 {
        padding-right: 0px;
        padding-left: 0px;
        color: #ffffff;
        font-family: "Mukta";
      }
      
    }
    .bithu_v1_baner_left {
      margin-top: 0px;
      justify-content: flex-start;
    }

    .bithu_v1_baner_right {
      margin: 70px auto;
      padding: 0px 68px;
      max-width: 568px;
      width: 100%;

      .bithu_v1_baner_right_img {
        width: 55%;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .bithu_v1_baner_left {
      h2 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 28px;
        line-height: 38px;
        
      }

      .banner_buttons{
        margin-top: 25px;
      }
    }

    .bithu_v1_baner_right {
      padding-right: 0px;

      .bithu_v1_baner_right_img_sect {
        height: 480px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .bithu_v1_baner_left {
      h2 {
        font-size: 37px;
      }

      h3 {
        font-size: 22px;
      }

      .coin-info {
        font-size: 14px;
        line-height: 29px;
      }

      .banner_buttons{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        
            .bithu-btn + .bithu-btn {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .bithu_v1_baner_right {
      padding-left: 34px;

      .mint_live_circle_sect{
        height: 90px;
        width: 90px;
        top: -36px;
        left: -40px;
    }
    }
  }

  @media (max-width: 375px) {
    .bithu_v1_baner_left {
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 360px) {
    .bithu_v1_baner_left {
      .coin-info {
        font-size: 13px;
      }
    }
  }
`;

export default BannerV1Wrapper;
