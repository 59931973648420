import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import buttonHoverShapeBlack from "../../../../assets/images/bg/instructions_bg.png";
import instructionsBackground from "../../../../assets/images/bg/instructional_bg5.png";

//import characterThumb from "../../../../assets/images/nft/Character1.png";
//import characterThumb from "../../../../assets/images/nft/banner_character_thumb.png";
import characterThumb from "../../../../assets/images/nft/characterv3.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_strait.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
//import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import swapInstrImage from "../../../../assets/images/bg/swap_instructionsv2.png"
import { useEffect, useState } from "react";
import { totalMintCount } from '../../../../utils/web3mint';
import { mintActive } from "../../../../config";
import { isMetaMaskInstalled } from '../../../../config';

const Info = () => {
  const { mintModalHandle, connectWalletModalHanlde, account, metamaskModalHandle, walletModalHandle} = useModal();
  const [remaining, setRemaining] = useState(0);
  useEffect(() =>{
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      //console.log(totaltMintedItems);
      setRemaining(totaltMintedItems);
    }

    calculateRemainingItems();
  },[])

  const handleWalletConnect = async () =>{
    //console.log("handleWalletConnect()")
    if(!isMetaMaskInstalled()){
      //console.log("isMetaMaskInstalled = false")
      metamaskModalHandle();
    }else{
      //console.log("isMetaMaskInstalled() = true")
      walletModalHandle();
    }
  }

  return (
    <BannerV1Wrapper id="">
      <div className="container">
        
        <div className="row">
          <div className="col text-center">
            <h1>Citizen 3025</h1>
            
          </div>
        </div>
        <div className="row">
          {/* <div className="mint_live_circle_sect_frame">
            { mintActive ?
                      <div className="mint_live_circle_sect">
                        <div className="mint_live_circle">
                          <span>
                            <img src={mintLiveDownArrow} alt="" />
                          </span>
                          <span className="mint_live_text rotated-style">
                            <img src={mintLiveText} alt="" />
                          </span>
                        </div>
                      </div>
                      : <div></div>
                    }
            </div> */}
        </div>
      
        <div className="row">
            <div className="col text-center">
              {/* <h1>About</h1> */}
              {/* <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Build your next generation of citizens with Citizen 3025. First-generation NFT holders can build their citizens with airdropped Nascents. Nascents combine with your citizen to produce a next-gen citizen in one of three classes. </h4> */}
              <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Introducing Citizen 3025 Free-to-own, generational NFT collection!

This unique digital collectible allows you to customize and dress your NFT with generational trait drops. From clothing and accessories to hair styles and facial features, you can create your own one-of-a-kind NFT.</h3>
              <br/><br/>
              <br/><br/>
              <h1>Generational Traits</h1>
              <h3>New generational traits will be dropped periodically with the option for current holders to swap out for new and rare traits!</h3>
              <img src={swapInstrImage} width="700px"/>
            </div>
        </div>
        <div className="row">
        {
          mintActive ? 
          <div className="col ">
            <div className="buttons_frame">
            
              {/* <div className="banner_buttons">
                {
                  account ? 
                  <Button lg variant="mint" onClick={() => mintModalHandle()}>
                    {" "}
                    Free Mint now!
                  </Button> :
                  <Button lg variant="mint" onClick={() => connectWalletModalHanlde()}>
                    {" "}
                    Free Mint now!
                  </Button>
                }
              </div> */}
            </div>
          </div>
          : <div></div>
        }
        </div>
         {/* <div className="row">
          <div className="image_instructions">
          <img
            src={instructionsBackground}
            className="image"
            alt="bithu nft button hover shape"
          />
          </div>
        </div> */}
        <div className="row">
            <div className="col text-center">

              {/* <h4>Incubating a Nascent by itself yields <span>a Warrior</span></h4>
              <h4>Incubating a Nascent plus Growth-Genesis will yield <span>a Noble</span></h4>
              <h4>Incubating a Nascent plus a Stem-Genesis will yield <span>Royal</span></h4> */}
              <br></br>
              <h1>Connect your wallet</h1 >
              <h3>Connect to now to reserve your free NFT!</h3>
              <div className="button_connect">
                <Button lg variant="mint" onClick={() => handleWalletConnect()}>
                      {" "} 
                      Connect
                </Button>
              </div>
              <br></br>
              <br></br>
              <br></br>
              {/* <h1>More Coming Soon</h1>
              <br></br>
              <h4>Follow us on <span style={{fontSize: '50px', fontFamily: 'Eternal'}}><a href="https://twitter.com/citizen_3025" target="_blank" rel="noreferrer"> Twitter</a></span> to get the latest up-to-date info for trait drops and further developments</h4> */}
              <br></br>
              <br></br>
              {/* <h1>Free to Own Mint is live!</h1> */}
            </div>
        </div>
        
      </div>
    </BannerV1Wrapper>
  );
};

export default Info;
