import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/mint-img.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { totalMintCount, mint } from '../../../utils/web3mint';
import { useEffect } from "react";
import { isMintAvailableForUser } from "../../../utils/web3mint";

const network = "goerli.";
const MintNowModal = () => {
  const [count, setCount] = useState(1);
  const [message, setMessage] = useState('Collect YOUR NFT before end');
  const [remaining, setRemaining] = useState(0);
  const { mintModalHandle } = useModal();
  const [isShowingSpinner, setIsShowingSpinner] = useState(false);
  const [mintAvailableForUser, setMintAvailableForUser] = useState(false);
  const [ismintProcessing, setIsMintProcessing] = useState(false)
  const [txHash, setTxHash] = useState('')
  let totalItems = 3025;
  let price = 0.03;

  const increaseCount = () => {
    if(count >= 10){
      setMessage('Maximum minting ammount exceeding!');
    }else{
      setCount(count + 1);
    }
  }

  const dcreaseCount = () => {
    if(count < 1){
      setMessage('Minimum minting ammount 1.');
    }else{
      setCount(count - 1);
    }
  }

  const onChnageCount = (val) => {
    if(count >= 10){
      setMessage('Maximum minting ammount exceeding!');
    }else if(count < 1){
      setMessage('Minimum minting ammount 1.');
    }else{
      setCount(val);
    }
  }


  const mintNow = async () => {
    /*if(count >= 10){
      setMessage('Maximum minting ammount exceeding!');
    }else if(count < 1){
      setMessage('Minimum minting ammount 1.');
    }else{*/
      //setMessage('Minting...');
      //setIsMintProcessing(true)
      let txn = await mint();
      //console.log("txn: " + JSON.stringify(txn));
      if(txn.hash.length) {
        setMessage('Minting...');
        setIsMintProcessing(true)
        setTxHash(txn.hash)
     //}
    }
  }
  

  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      setRemaining(totalItems - totaltMintedItems);
    }

    calculateRemainingItems();
  },[totalItems]);

  useEffect(() => {
    const isMintAvailable = async () => {
      setIsShowingSpinner(true)
      console.log("isShowingSpinner: " + isShowingSpinner)
      let isMintAvailable = await isMintAvailableForUser()
      setMintAvailableForUser(isMintAvailable)
      setIsShowingSpinner(false)
      console.log("isShowingSpinner: " + isShowingSpinner)
      console.log("isMintAvailable: " + isMintAvailable)
    }
    isMintAvailable()
  }, []);
  
  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
        {!mintAvailableForUser ? <div className="mint_modal_box"><div className="mint_modal_content"><div className="modal_header"><h2>NFT already minted</h2><button onClick={() => mintModalHandle()}>
                <FiX />
              </button></div></div></div> : 
          <div className="mint_modal_content">
            <div className="modal_header">
              
              {
                <h2>{message}</h2>
              }
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              { ismintProcessing ? <div className="mint_count_list"><h5><a href={"https://etherscan.io/tx/" + txHash} target="_blank" rel="noopener noreferrer">Click here to view your transaction on EtherScan</a></h5></div> :
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {remaining}/<span>2000</span>
                    </h5>
                  </li>
                </ul>
              </div>
              }
              { /*message && <p>{message}</p>*/}
              {!ismintProcessing ? <div className="modal_mint_btn">
                <Button lg variant="mint" onClick={() => mintNow() }>
                  Mint Now
                </Button>
              </div> : <div><h2>Minting...</h2></div>}
            </div>
            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
          }
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
