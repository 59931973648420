import { createStore, combineReducers } from 'redux';

// Reducer 1
const fetchImagesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_IMAGES':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Reducer 2
const cartReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_ITEM_TO_CART':
      return [...state, action.payload];
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  user: fetchImagesReducer,
  cart: cartReducer,
});

// Create a global store
export default createStore(rootReducer);

